import React from 'react'
import styled from 'styled-components'
import { ReUseHOneTag } from './ReUseHOneTag'
import { ReUsePtag } from './ReUsePtag'

const AppHackettRaindropWebinarBannerWapper = styled.div`
  max-width: 1920px;
  margin: auto;
  height: auto;

  .HackettRaindropWebinarSection {
    height: 40vw;
    display: flex;
    justify-content: space-between; /* Space between text and image */
    align-items: center; /* Vertically center content */
    padding: 0 80px; /* Add padding to the sides */
    background-color: #102c64;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='400' height='400' viewBox='0 0 800 800'%3E%3Cg fill='none' stroke='%23035484' stroke-width='1'%3E%3Cpath d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3Cg fill='%23047CC2'%3E%3Ccircle cx='769' cy='229' r='5'/%3E%3Ccircle cx='539' cy='269' r='5'/%3E%3Ccircle cx='603' cy='493' r='5'/%3E%3Ccircle cx='731' cy='737' r='5'/%3E%3Ccircle cx='520' cy='660' r='5'/%3E%3Ccircle cx='309' cy='538' r='5'/%3E%3Ccircle cx='295' cy='764' r='5'/%3E%3Ccircle cx='40' cy='599' r='5'/%3E%3Ccircle cx='102' cy='382' r='5'/%3E%3Ccircle cx='127' cy='80' r='5'/%3E%3Ccircle cx='370' cy='105' r='5'/%3E%3Ccircle cx='578' cy='42' r='5'/%3E%3Ccircle cx='237' cy='261' r='5'/%3E%3Ccircle cx='390' cy='382' r='5'/%3E%3C/g%3E%3C/svg%3E");
    @media (max-width: 425px) {
      height: 40vh;
    }
  }

  .HackettRaindropWebinarContentSection {
    flex: 1; /* Take up available space */
    max-width: 60%; /* Limit width of text content */
  }

  .IDCSpotlightBannerHeading {
    text-align: left;
    margin-top: 55px;
    font-size: 3rem;
    color: #f1f1f1;
    @media (max-width: 1920px) {
      font-size: 3.5rem;
    }
    @media (max-width: 1650px) {
      font-size: 2.1rem;
    }
    @media (max-width: 1090px) {
      font-size: 2rem;
    }
    @media (max-width: 770px) {
      font-size: 1.5rem;
    }
    @media (max-width: 425px) {
      padding-top: 5px;
      font-size: 1rem;
      width: 70vw;
    }
  }

  .IDCSpotlightBannerPara {
    width: 100%; /* Full width of the content section */
    text-align: left;
    font-size: 1.2rem;
    color: #f1f1f1;
    @media (min-width: 1650px) {
      font-size: 1.3rem;
    }
    @media (max-width: 768px) {
      font-size: 0.9rem;
    }
    @media (max-width: 425px) {
      font-size: 0.6rem;
      width: 70vw;
    }
  }

  button {
    display: flex;
    background-color: #34a1d5;
    color: white;
    border: none;
    border-radius: 42px;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 20px; /* Space between paragraph and button */
    @media (max-width: 425px) {
      font-size: 0.8rem;
      margin: 0;
      padding: 8px 15px;
    }

    &:hover {
      background-color: #fff;
      color: #111;
    }
  }

  .HackettCLMBannerImage {
    padding-top: 44px;
    flex: 1; /* Take up available space */
    display: flex;
    justify-content: flex-end; /* Align image to the right */
    align-items: center; /* Vertically center the image */
    @media (max-width: 425px) {
      display: none;
    }

    img {
      max-width: 90%; /* Ensure image doesn't overflow */
      height: auto; /* Maintain aspect ratio */
      border: 2px solid #34a1d5;
      border-radius: 12px;
    }
  }
`

export const AppHackettRaindropWebinarBanner: React.FC = () => {
  return (
    <AppHackettRaindropWebinarBannerWapper>
      <div className="HackettRaindropWebinarSection">
        <div className="HackettRaindropWebinarContentSection">
          <ReUseHOneTag
            Heading="The 2030 Team Interview: AI Agents, CPOs, and Operating Models Reimagined"
            HeadingStyle="IDCSpotlightBannerHeading"
          />
          <ReUsePtag para="The Hackett Group and Raindrop explore the future" paraStyle="IDCSpotlightBannerPara" />
          <button
            onClick={() => {
              window.open('https://us02web.zoom.us/webinar/register/WN_qN5LOZXoT2inh85gLKfoXQ#/registration', '_blank')
            }}
          >
            Register Now
          </button>
        </div>
        <div className="HackettCLMBannerImage">
          <img
            src="https://storage.googleapis.com/raindroppublic/website_data/Hackett-Group-Webinar-Resources.jpg"
            alt="Banner Image"
          />
        </div>
      </div>
    </AppHackettRaindropWebinarBannerWapper>
  )
}
